.swapForm {
  @apply top-[calc(100%-50px)] xs:top-[calc(100%-24px)] left-[calc(50%-71px)] xs:left-[calc(50%-34px)] shadow-custom-1;
}

.body {
  @apply transition-opacity h-full w-full absolute top-0 left-0;
}

.tooltipText {
  @apply mt-8px mr-10px mb-6px ml-21px;

  max-width: 195px;
}

.tooltipLongText {
  @apply mt-8px mr-10px mb-6px ml-21px;

  max-width: 240px;
}

.toggleClicker {
  transition: width 0.25s ease-in-out, transform 0.25s ease-in-out;
}