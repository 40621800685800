.wrapper {
  filter: drop-shadow(0px 25px 30px rgba(0, 57, 149, 0.29));

  transition: width 0.25s ease-in-out, height 0.25s ease-in-out;
}

.column {
  @apply max-w-20% xs:max-w-none xs:w-full xs:flex xs:flex-col xs:items-start;

  transition: opacity 0.5s ease-in-out;
}

.delimiter {
  @apply w-2px h-50px xs:h-5px xs:w-full;
}

.show {
  @apply opacity-100 pointer-events-auto;
}

.hide {
  @apply opacity-0 pointer-events-none;
}