@tailwind base;

@tailwind components;

@tailwind utilities;

@import "react-toastify/dist/ReactToastify.min.css";

@import "fonts";
@import "toastify";
@import "dropdown";
@import "table";
@import "perfectScrollbar";
@import "analyticGraphic";
@import "menu";
@import "modal";

html,
body {
  padding: 0;
  margin: 0;
  font-family: Gotham Rounded, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 500;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  letter-spacing: 0.05em;
  outline: none;
  box-shadow: none;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  font-family: Gotham Rounded, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  &::-webkit-scrollbar {
    display: none;
  }
}

.blurWrapper {
  @apply bg-white bg-opacity-5;
  backdrop-filter: blur(40px);
}

@layer components {
  .form {
    box-shadow: 0 35px 65px rgba(78, 78, 78, 0.15);
    backdrop-filter: blur(50px);
  }

  .form-xs {
    box-shadow: none;
    backdrop-filter: none;
  }

  .form-right-column-xs {
    backdrop-filter: blur(50px);
  }
}

.modal-description {
  &__title {
    @apply text-gray-400 text-xl uppercase text-opacity-60 xs:text-xs xs:tracking-normal xs:px-25px;
  }

  &__value {
    @apply text-gray-400 text-xl tracking-normal mt-8px xs:text-base xs:uppercase xs:px-25px xs:mt-0;
  }
}

.body-details {
  @apply pt-22px pr-30px pb-45px pl-58px xs:py-44px xs:px-22px;
}

.details-form {
  @apply pt-42px pr-10px pb-10px pl-32px xs:px-0 xs:pt-46px;
}

.settings-input__yellow-btn {
  @apply bg-yellow-400 text-white text-sm xs:text-xs uppercase font-medium tracking-normal py-4px xs:py-2px px-7px xs:px-6px rounded-5 shadow-custom-6;
}

.backdrop-filter-blur-50 {
  backdrop-filter: blur(50px)
}