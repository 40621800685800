.dropdown {
  &__menu {
    @apply border-4 border-white rounded-16;
    top: calc(100% + 5px) !important;
    left: -4px !important;
    width: calc(100% + 9px) !important;
    backdrop-filter: blur(50px) !important;
    margin: 0 !important;
    min-width: 60px;
    box-shadow: none !important;
    background: #F5F5F5 !important;
    border-radius: 22px !important;

    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background: rgba(255, 255, 255, 0.5);

      z-index: -1;

      filter: blur(10px);
    }
  }

  &__menu-list {
    padding: 14px 7px 11px 7px !important;
  }

  &__option {
    @apply font-bold text-sm text-gray-400;
    background-color: transparent;
    padding: 6px 11px !important;
    cursor: pointer;

    &--is-focused {
      @apply bg-transparent;
      //background-color: transparent !important;
    }

    &--is-selected {
      background-color: rgba(255, 255, 255, 0.8) !important;
      color: #21314A !important;
      @apply bg-white rounded-full bg-opacity-80 text-white;
    }

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &__indicator-separator {
    display: none;
  }
}

.token-list-dropdown {
  .dropdown {
    @apply w-full h-50px xs:h-36px bg-white rounded-25 border-3 xs:border-2 border-gray-260;

    &__control {
      @apply w-full h-full;

      background: transparent !important;
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
    }

    &__placeholder {
      @apply pl-10px;
    }

    &__indicators {
      @apply mr-20px;
    }

    &__value-container {
      max-width: 85%;
    }

    &__dropdown-indicator {
      @apply bg-center bg-no-repeat bg-contain w-19px h-19px -rotate-90;
      background-image: url("/icons/arrowLeft.svg");

      svg {
        @apply hidden;
      }
    }
  }
}

.pagination-list-dropdown {
  .dropdown {
    &__control {
      @apply bg-transparent border-none;
      outline: none !important;
      box-shadow: none !important;
    }

    &__indicator-separator {
      @apply hidden;
    }

    &__single-value {
      @apply text-sm text-gray-400;
    }

    &__value-container {
      min-width: 35px;
    }

    &__dropdown-indicator {
      @apply bg-center bg-no-repeat bg-contain w-11px h-7px rotate-90;
      background-image: url("/icons/arrowLeft.svg");

      svg {
        @apply hidden;
      }
    }
  }
}

.custom-dropdown {
  .dropdown {
    &__control {
      @apply bg-transparent border-none min-h-28px xs:min-h-0;
      outline: none !important;
      box-shadow: none !important;
    }

    &__indicator-separator {
      @apply hidden;
    }

    &__value-container {
      @apply p-0;
      min-width: 50px;
    }

    &__single-value {
      @apply m-0;
    }

    &__dropdown-indicator {
      @apply bg-center bg-no-repeat bg-contain w-11px h-7px -rotate-90;
      background-image: url("/icons/arrowLeft.svg");

      svg {
        @apply hidden;
      }
    }
  }
}

.search-autocomplete {
  .dropdown {
    @apply flex flex-1;

    &__control {
      @apply w-full border-0 bg-transparent outline-0 shadow-none;
    }

    &__indicators {
      @apply invisible;
    }

    &__value-container {
      @apply pl-16px;
    }

    &__placeholder {
      @apply font-medium text-base text-gray-700 xs:text-xs uppercase;
    }

    &__option {
      @apply text-gray-400 rounded-lg cursor-pointer;

      &--is-focused {
        @apply text-gray-400 bg-gray-200;
      }

      &--is-selected {
        @apply text-blue-400 bg-transparent;
      }
    }
  }
}