.assetForm {
  @apply w-full bg-gray-200 border-b-10 xs:border-b-4 border-gray-100 relative rounded-tr-60 xs:rounded-t-20 pt-[82px] xs:pt-[70px] pb-39px pr-20px pl-66px xs:px-37px xs:py-24px;
  max-height: 290px;
}

.title {
  @apply font-medium text-xl xs:text-xs text-gray-400 text-opacity-60 uppercase tracking-normal min-w-187px max-w-50% xs:max-w-none xs:min-w-0;
}

.valueWrapper {
  @apply max-w-50% xs:max-w-none;
}

.value {
  @apply font-medium text-xl xs:text-xs text-gray-400 uppercase tracking-normal w-full;
}

.leftColumn {
  @apply w-61% xs:w-58%;
}

.rightColumn {
  @apply w-39% xs:w-42%;
}

.infoRow {
  @apply flex items-center mb-11px xs:mb-25px xs:flex-col xs:items-start;
}

.infoRowNoMobilePadding {
  @apply mb-11px xs:mb-0;
}

.valWrapper {
  @apply max-w-full;

  span {
    @apply w-full;
  }
}