.body {
  @apply transition-opacity h-full w-full absolute top-0 left-0;
}

.listWrapper {
  @apply overflow-hidden relative pt-[50px];
  height: calc(100% - 86px);

  [class~="ps__rail-y"]{
    transform: scaleY(0.75) translateX(-1px);
    transform-origin: top center;
  }
}