.body {
  @apply transition-opacity h-full w-full absolute top-0 left-0;
}

.assetForm {
  @apply pt-61px pb-70px pl-33px pr-20px xs:pt-26px xs:pb-46px xs:px-3px;
}

.swapForm {
  @apply w-112px h-112px shadow-custom-1;
  top: calc(100% - 50px);
  left: calc(50% - 56px);
}

.receiveForm {
  @apply pt-73px pb-20px pl-33px pr-20px xs:pt-39px xs:px-3px;
}