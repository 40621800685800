.body {
  @apply transition-opacity h-full w-full absolute top-0 left-0;
}

.assetForm {
  @apply pt-[80px] xs:pt-[60px] pb-39px pl-66px pr-30px xs:pb-32px xs:px-11px w-full bg-gray-200 border-b-10 border-gray-100 p-10px relative rounded-tr-60 xs:rounded-t-20;
}

.swapForm {
  @apply w-112px h-112px top-[calc(100%-50px)] left-[calc(50%-56px)] shadow-custom-1;
}

.receiveForm {
  @apply pt-47px pb-20px px-40px xs:px-20px xs:pt-36px xs:pb-10px;
}

.title {
  @apply font-medium text-xl xs:text-xs text-gray-400 text-opacity-60 uppercase tracking-normal min-w-187px xs:min-w-fit;
}

.value {
  @apply font-medium text-xl xs:text-xs text-gray-400 uppercase tracking-normal xs:-mt-1px;
}

.leftColumn {
  @apply w-61% xs:w-58%;
}

.rightColumn {
  @apply w-39% xs:w-42%;
}

.infoRow {
  @apply flex xs:flex-col items-center xs:items-start mb-11px xs:mb-25px;
}

.calcColumn {
  @apply flex flex-col min-w-187px xs:min-w-fit;
}

.yellowBtn {
  @apply bg-yellow-400 text-white text-sm xs:text-xs uppercase font-medium tracking-normal pt-5px pb-3px xs:py-2px px-9px xs:px-6px rounded-5 shadow-custom-6;
}