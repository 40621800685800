.wrapper {
  @apply w-440px h-122px xs:w-full xs:h-fit rounded-60 xs:rounded-32 pt-12px pb-10px px-10px xs:pt-14px xs:pb-1px xs:px-45px xs:mb-20px;

  box-shadow: 0 25px 30px rgba(64, 67, 63, 0.3);

  transition: background-color 0.25s ease-in-out;
}

.notificationIcon {
  width: 19%;
}

.title {
  line-height: 17px;
}

.progressLineAnimation {
  transition-property: width;
}