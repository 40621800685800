.form {
  @apply pt-85px pb-0 pr-29px pl-60px xs:px-18px xs:py-35px;
}

.searchWrapper {
  @apply w-401px xs:w-full shadow-custom-3-inset;
}

.listWrapper {
  @apply overflow-hidden h-[calc(100%-86px)] relative;

  //-webkit-mask-image: -webkit-gradient(linear, left 55%, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0.3)));
  //mask-image: linear-gradient(rgba(0, 0, 0, 1) 55%, rgba(0, 0, 0, 0.3));

  [class~="ps__rail-y"]{
    transform: scaleY(0.75) translateX(-1px);
    transform-origin: top center;
  }
}