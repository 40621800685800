.wrapperError {
  box-shadow: 0 25px 30px rgba(219, 0, 0, 0.31);
}

.wrapperSuccess {
  box-shadow: 0 25px 30px rgba(31, 173, 123, 0.3);
}

.text {
  letter-spacing: 0.107em;
}

.delimiter {
  @apply border-0 bg-white bg-opacity-20 w-5px h-full min-h-50px xs:hidden;
}