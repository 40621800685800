.wrapper {
  @apply w-228px h-70px xs:w-170px xs:h-63px;
}

.wrapperEmpty {
  @apply xs:w-150px h-73px xs:h-63px;
}

.text {
  @apply uppercase font-bold text-sm xs:text-xs text-gray-400 opacity-100;
}

.circle {
  @apply bg-gray-800 flex justify-center items-center rounded-full w-34px h-34px xs:w-29px xs:h-29px drop-shadow-custom-2;
}
