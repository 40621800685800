.tableWrapper {
  @apply block max-w-full;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    @apply block max-w-full w-full overflow-x-scroll overflow-y-hidden;

    .table {
      @apply w-full;
      border-spacing: 0;
    }

    .thead {
      @apply min-w-fit;

      .th {
        @apply py-12px px-19px font-medium;
      }
    }

    .tbody {
      .tr {
        //overflow: hidden;

        //&:first-child {
        //  .td {
        //    &:first-child {
        //      border-top-left-radius: 12px;
        //    }
        //
        //    &:last-child {
        //      border-top-right-radius: 12px;
        //    }
        //  }
        //}
        //
        //&:last-child {
        //  .td {
        //    &:first-child {
        //      border-bottom-left-radius: 12px;
        //    }
        //
        //    &:last-child {
        //      border-bottom-right-radius: 12px;
        //    }
        //  }
        //}
      }

      .td {
        @apply pt-19px pb-18px pl-19px pr-15px;
      }
    }

    .tr {
      :last-child {
        .td {
          @apply border-b-0;
        }
      }
    }

    .th,
    .td {
      @apply text-sm m-0 px-2;
      font-weight: 400;
    }

    /* The secret sauce */
    /* Each cell should grow equally */
    //width: 1%;
    /* But "collapsed" cells should be as small as possible */
    &.collapse {
      width: 0.0000000001%;
    }

    &.selectable {
      .thead {
        .tr {
          @apply py-0 px-35px xs:px-0;
        }
      }

      .tbody {
        .tr {
          @apply py-0 px-35px xs:px-0;

          &:after {
            @apply block absolute -top-2px left-8px w-[calc(100%-16px)] h-[calc(100%+2px)] bg-white rounded-50 border-2 border-gray-60 opacity-0;
            content: '';

            z-index: -1;

            box-shadow: 0 10px 14px rgba(0, 0, 0, 0.07);

            transition: opacity 0.15s ease-in-out;
          }

          &:hover {
            &:after {
              @apply opacity-100;
            }
          }
        }
      }
    }
  }

  .headerTr {

  }

  .headerTh {
    @apply font-medium text-sm text-opacity-60 text-gray-320 border-b-2 border-gray-60 relative;

    &:after {
      @apply w-full h-9px absolute top-full left-0 opacity-10;
      content: '';
      background: linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    }

    &.left {
      @apply text-left justify-start;
    }

    &.right {
      @apply text-right justify-end;
    }

    &.center {
      @apply text-center justify-center;
    }
  }

  .bodyTd {
    @apply border-b-2 border-gray-60;

    &.left {
      @apply text-left justify-start;
    }

    &.right {
      @apply text-right justify-end;
    }

    &.center {
      @apply text-center justify-center;
    }

    &.first {
      @apply relative;

      &:before {
        @apply absolute -top-1px left-0 w-5px h-[calc(100%+2px)];
        content: "";
      }

      &.inner {
        text-indent: 15px;

        &.lastInnerRow {
          @apply overflow-hidden;
        }
      }
    }
  }

  .tbody {
    @apply relative;
  }

  .bodyTr {

  }
}