.formWrapper {
  @apply relative;
  transition: width 0.25s ease-in-out;
}

.formSearchWrapper {
  @apply px-35px mt-7px xs:px-17px xs:mt-3px;
}

.form {
  transition: max-height 0.25s ease-in-out, height 0.25s ease-in-out;
}

.formShadowed {
  box-shadow: inset 0 3px 15px rgba(0, 0, 0, 0.16);
}

.placeholder {
  transition: font-size 0.25s ease-in-out, padding 0.25s ease-in-out;
}

.tokenNameWrapper {
  max-width: calc(100% - 32px);
}

.formBody {
  @apply absolute w-full top-0 right-0;
  transition: opacity 0.25s ease-in-out;
}

.formBodyShow {
  opacity: 1;
  pointer-events: all;
}

.formBodyHide {
  opacity: 0;
  pointer-events: none;
}

.arrowStepWrapper {
  @apply xs:w-31px xs:h-31px xs:rounded-full xs:bg-white xs:flex xs:justify-center xs:items-center xs:shadow-custom-7;
}
