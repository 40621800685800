.walletBtnAnimated {
  animation: pulse-border-red 1s 3 cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes pulse-border-red {
  0% {
    box-shadow: 0 0 10px 5px rgba(230, 0, 0, 0.9);
  }
  90%, 100% {
    box-shadow: 0 0 10px 25px rgba(230, 0, 0, 0.1);
  }
}