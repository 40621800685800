@import 'react-toastify/dist/ReactToastify.min.css';

.Toastify {
  &__toast, &__toast-body, &__toast-container {
    @apply p-0 m-0 bg-transparent shadow-none;

    &>div {
      @apply w-full;
    }
  }

  &__toast-container {
    @apply w-auto xs:w-full xs:px-20px;

    &--bottom-center {
      @apply pt-0 xs:pt-0;
    }
  }

  &__toast {
    @apply overflow-visible mb-15px;
  }

  &__toast-icon {
    @apply hidden;
  }

  &__close-button {
    @apply w-24px h-24px xs:w-13px xs:h-13px absolute top-[calc(50%-10px)] right-38px xs:right-26px xs:top-24px cursor-pointer opacity-100;
    background-image: url('/icons/iconCross.svg');
    background-position: center;
    background-size: cover;

    svg {
      @apply hidden;
    }
  }
}

.toast-popper {
  &__arrow {
    display: block;
  }

  > span {
    &[data-popper-placement="top"] {
      .toast-popper__arrow {
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        border-top: 11px solid rgb(33, 49, 74);
        top: 100%;
      }
    }

    &[data-popper-placement="bottom"] {
      .toast-popper__arrow {
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        border-bottom: 11px solid rgb(33, 49, 74);
        bottom: 100%;
      }
    }
  }
}