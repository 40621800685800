.assetForm {
  @apply w-full bg-gray-200 border-b-10 xs:border-b-4 border-gray-100 relative rounded-tr-60 xs:rounded-t-20 pt-[82px] xs:pt-[70px] pb-39px pr-20px pl-66px xs:px-37px xs:pb-6px;
  max-height: 290px;
}

.title {
  @apply font-medium text-xl xs:text-xs text-gray-400 text-opacity-60 uppercase tracking-normal min-w-187px max-w-50% xs:max-w-none xs:min-w-fit;
}

.value {
  @apply font-medium text-xl xs:text-xs text-gray-400 uppercase tracking-normal w-full;
}

.leftColumn {
  @apply w-61% xs:w-58%;
}

.rightColumn {
  @apply w-39% xs:w-42%;
}

.infoRow {
  @apply flex items-center mb-11px xs:mb-25px xs:flex-col xs:items-start;
}

.infoRowNoMobilePadding {
  @apply mb-11px xs:mb-0;
}

.inputWrapper {
  @apply w-269px xs:w-full h-100px xs:h-52px rounded-full border-3 border-white bg-opacity-60 border-opacity-60 bg-gray-220 flex flex-col xs:flex-row items-end xs:items-center xs:justify-between px-34px xs:px-22px py-22px xs:pt-15px xs:pb-10px;
  box-shadow: inset 0 3px 15px rgba(0, 0, 0, 0.16);
}