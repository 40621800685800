.circleWrapper {
  @apply w-52px h-52px flex items-center justify-center;
}

.circle {
  @apply w-20px h-20px;

  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
}

.circleRing {
  @apply w-24px h-24px;
  box-sizing: content-box;
}

.ringGreenAnimation {
  animation: pulse-ring-green 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.ringRedAnimation {
  animation: pulse-ring-red 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

@keyframes pulse-dot {
  0% {
    transform: scale(.95);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulse-ring-green {
  0% {
    width: 24px;
    height: 24px;
    border-color: rgba(0, 123, 50, 1);
  }
  90%, 100% {
    width: 38px;
    height: 38px;
    border-color: rgba(0, 123, 50, 0.05);
  }
}

@keyframes pulse-ring-red {
  0% {
    width: 24px;
    height: 24px;
    border-color: rgba(230, 0, 0, 1);
  }
  90%, 100% {
    width: 38px;
    height: 38px;
    border-color: rgba(230, 0, 0, 0.05);
  }
}

.body {
  @apply transition-opacity h-full w-full top-0 left-0 py-45px pr-30px pl-86px xs:py-39px xs:px-23px;
}